<script lang="ts">
    import python from 'svelte-highlight/languages/python';
    import 'svelte-highlight/styles/github.css';

    import Blog from '../Blog.svelte';

    import Highlight from 'svelte-highlight';
    import { default as codeStyle } from 'svelte-highlight/styles/agate';
    import Sidenote from '../Sidenote.svelte';
    import type { RouteLocation } from 'svelte-routing/types/Route';
    import Author from '../Author.svelte';
    import WorkFooter from '../WorkFooter.svelte';
    import type { Entry } from '../DetailsTable.svelte';
    import DetailsTable from '../DetailsTable.svelte';
    import { getBlogPostMeta } from '../blog_posts';
    import Foldout from '../Foldout.svelte';

    export let location: RouteLocation;

    let primitives: Entry[] = [
        {
            title: `string(s)`,
            examples: `string("hello")
parse("hello") => "hello"`,
            description: 'Parses the given string and returns it.',
        },
        {
            title: `strings(s1, ...)`,
            examples: `strings("a", "b", "c")
parse("c") => "c"`,
            description: 'Parses any of the given strings.',
        },
        {
            title: `regex(s)`,
            examples: `regex("\\d+").map(int)
parse("123") => 123`,
            description: `Parses the given regex and returns the matched string. Regexes are quite compact, after all, and can be
                useful as subcomponents.`,
        },
        {
            title: `|`,
            examples: `string("a") | string("b")
parse("b") => "b"`,
            description: '"a" OR "b"',
        },
        {
            title: `>>`,
            examples: `string("a") >> string("b")
parse("ab") => "b"`,
            description: 'Parse as a sequence, but return the result of the second parser.',
        },
        {
            title: `<<`,
            examples: `string("a") << string("b")
parse("ab") => "a"`,
            description: 'Parse as a sequence, but return the result of the first parser.',
        },
        {
            title: `.result(v)`,
            examples: `string("one").result(1)
parse("one") => 1`,
            description: 'If parsed successfully, return the given value.',
        },
        {
            title: `.many()`,
            examples: `string("z").many()
parse("zzz") => ["z","z","z"]`,
            description: 'Parses 0 or more of that parser. Like a regex <code>*</code>. Returns a list.',
        },
        {
            title: `.map(f)`,
            examples: `regex("\\w+").map(reversed)
parse("alice") => "ecila"`,
            description: 'Returns the value of <code>f</code> called with the parsed value.',
        },
        {
            title: `seq(a,b,c...)`,
            examples: `seq(digit, digit)
parse("32") => ["3", "2"]`,
            description: 'Parses each parameter in sequence, and then returns a list of the parsed values.',
        },
        {
            title: `.combine(f)`,
            examples: `seq(digit, digit).combine(
    lambda a, b: int(a) + int(b)
)
parse("32") => 5`,
            description: 'Takes a parsed list and calls a function with the contents of the list as parameters.',
        },
        {
            title: `.sep_by(c)`,
            examples: `digit.sep_by(string(","))
parse("1,2,3") => [1,2,3]`,
            description: 'Like <code>.many</code>, but with a parser inserted between each repetition.',
        },
    ];
</script>

<Blog {location}>
    <h1>Having trouble understanding your regexes? Try parser combinators!</h1>
    <Author
        name="Aron Granberg"
        date={getBlogPostMeta('parser_combinators').date}
        imageUrl="/aron_thumbnail_80x80@1x.webp"
    />
    <p>In my experience, parser combinators has one of the highest ratios of</p>
    <div class="fraction">
        <div class="fraction-text">Usefulness for all kinds of developers</div>
        <div class="fraction-line" />
        <div class="fraction-text">People who actually know about them</div>
    </div>
    <p>
        Regexes, on the other hand, it seems like almost everyone knows about and has used at some point. But while
        regexes are great in many ways, they fall apart and become an unmaintainable mess if you try to use them in
        complex settings. Not to mention the fact that there are many common things that regexes simply cannot parse,
        like for instance balanced parentheses.
    </p>
    <p>
        If you’re among the many programmers who have never heard of parser combinators, or if you just want to know
        more about them, then you will find this blog post helpful. You’ll learn about the benefits of parser
        combinators as well as how to use them, and you only need some basic familiarity with regexes.
    </p>
    <h2>A motivating example</h2>

    <p>
        To understand how parser combinators can make your parsing code more understandable and expressive, let’s start
        with a motivating example!
    </p>
    <p>
        Sometimes you have to parse some outright horrible data. For example, the other day I found myself wanting to
        parse some property listings, and I was baffled by the many creative manners in which the realtors described the
        floors the apartments were on:
    </p>
    <Highlight
        language={python}
        code={`1                        # First floor, simple enough
1 of 2                   # First floor out of two, fine
1 of 2 (elevator exists) # Okay, there's an elevator too
2 floors up              # Fair enough, good to know that it's not in the basement
(2 floors up)            # Wrapping the data in parentheses because... why not?
floor 1                  # First floor
1 & 2                    # First and second floor
1 and 2                  # Same, but using words
1 + 2                    # Same, but making mathematicians cry
1-3                      # Floors 1 through 3
floor 1.5                # Haha, did you think we only had to deal with integers?
second floor             # Why use numbers when you can use words?
upper floor              # Probably also second floor
upr                      # Abbreviation for upper floor
upr fl                   # Also abbreviation for upper floor
...                      # It goes on and on...`}
    />

    <p>
        If you think: "This can be solved using a regex!", then you are not wrong. But if you succeed at writing a regex
        for this that is still somewhat maintainable, then you are a better programmer than I am.
    </p>
    <p>
        But if you are a mere mortal like me, I’ll show you that there’s a better way. One that is maintainable,
        extendable and just easier to work with compared to regexes.
    </p>
    <h2>Parser Combinators</h2>
    <p>
        Parser combinators are a way to parse text into nice semantic information. And you don’t have to take any
        detours via parse trees or capturing groups.
    </p>
    <p>
        Suppose for example that we want to parse the text "My favorite digit is [digit]". Using the
        <a href="https://github.com/python-parsy/parsy">Parsy</a> Python library we can define a parser combinator like so:
    </p>
    <Highlight
        language={python}
        code={`from parsy import string, digit

greeting = string("My favorite digit is ") >> digit.map(int)
assert greeting.parse("My favorite digit is 7") == 7`}
    />
    <p>
        Pay attention to our use of the <code>>></code> operator in the above example. This is one of the common
        operators used to combine parser combinators, similar to how regexes have their own syntax like <code>\d</code>
        or <code>a|b</code>. The difference is that when writing regexes you put all the syntax into a single string,
        while parser combinators are combined using operators exposed by the programming language, or using functions.
        This is the key factor that makes them so composable and, hence, maintainable.
    </p>
    <h2>It’s parsers all the way down</h2>

    <p>
        A parser combinator is essentially just a fancy way of saying that we have some function <code>f</code> that
        takes a
        <code>string</code> and outputs either a parsed result or an error.
    </p>
    <p>
        To define a parser combinator, you start with primitives (usually a string or a regex) and then you combine the
        primitives into progressively more sophisticated parsers. For example:
    </p>
    <Highlight
        language={python}
        code={`# Matches "a" or "b"
f = string("a") | string("b")`}
    />

    <p>
        The beauty of this is that when we combine these parsers, we can still think of the combination as a single
        function that returns a parsed result or an error.
    </p>
    <p>
        Every parser combinator returns a result. For primitives, this is usually just the consumed string. But you can
        change this. In the end what you want to get out is <em>semantic</em> information, not raw text. So if you are parsing
        a date, for instance, it’s nicer if the output is a structured date object rather than just the date in a text format.
    </p>

    <Highlight
        language={python}
        code={`# Matches the string "true" and returns the boolean True on a successful parse
string("true").result(True)

# Matches a digit (0-9) and converts the result to an integer
digit.map(int)`}
    />

    <p>
        When combining parser combinators, usually there are some parts of the result which you are interested in, and
        some parts which don’t contribute any semantic information. Using the <code>>></code> and <code>&lt;&lt;</code>
        operators you can chain combinators and choose which result you care about:
    </p>
    <Highlight
        language={python}
        code={`from parsy import regex, string

# Parses "my name is <name>" but returns only the name
string("my name is ") >> regex("\\w+")

# Compatibility for Yoda-speak
regex("\\w+") << string(", my name is")`}
    />
    <p>The returned result is always the one the arrow points to.</p>

    <p>
        If there are many partial results that you want to combine into a single object, you can do that too. This is
        similar to using multiple capture groups in regexes.
    </p>

    <Highlight
        language={python}
        code={`from parsy import regex, seq, string
# seq combines all results into an array. Here we parse "ab" into ["a", "b"]:
seq(string("a"), string("b"))

# Parses "Romeo and Juliet" into ("Romeo", "Juliet")
seq(regex("\\w+"), string(" and "), regex("\\w+")) \\
    .combine(lambda name1, _, name2: (name1, name2))`}
    />

    <Foldout>
        <span slot="preview">What are all these different API calls? Click to reveal.</span>
        <DetailsTable entries={primitives} slot="content" />
    </Foldout>

    <p>
        So far, this may just seem like a more verbose way of writing regexes. So what advantages do parser combinators
        have?
    </p>

    <h2>Composability</h2>

    <p>
        One major advantage of parser combinators is their amazing composability. Take the following example of parsing
        a date string formatted as YYYY-MM-DD:
    </p>

    <Highlight
        language={python}
        code={`from parsy import digit, string, seq, regex
from datetime import datetime

# E.g. "42"
number = regex("\\d+").map(int)

# E.g. "1969-07-20"
date = seq(
    number,
    string("-"),
    number,
    string("-"),
    number
).combine(lambda year, _1, month, _2, day: datetime(year, month, day))

assert date.parse("2023-01-01") == datetime(2023, 1, 1)`}
    />

    <p>Now that we have this, we can treat it as a primitive and include it in other parsers:</p>

    <Highlight
        language={python}
        code={`name = regex("\\w+")
birthday_parser = seq(
    name,
    string(" lived between "),
    date,
    string(" and "),
    date
).combine(lambda name, _1, born, _2, died: (name, born, died))

assert birthday_parser.parse("Einstein lived between 1879-03-14 and 1955-04-18") == \\
    ("Einstein", datetime(1879, 3, 14), datetime(1955, 4, 18))`}
    />

    <p>
        Notice how we don’t have to care about the inner workings of <code>date</code> at all. Once we have it as a named
        parser, we can reuse it however we want, and feel safe that it is encapsulated in a neat box that we don’t have to
        think about.
    </p>

    <p>If, on the other hand, we had written this as a regex, it might have looked something like this:</p>

    <Highlight
        language={python}
        code={`import re
from datetime import datetime

r = re.compile('(\\w+) lived between (\\d\\d\\d\\d)-(\\d\\d)-(\\d\\d) and (\\d\\d\\d\\d)-(\\d\\d)-(\\d\\d)')

m = r.match("Einstein lived between 1879-03-14 and 1955-04-18")
name = m[1]
born = datetime(int(m[2]), int(m[3]), int(m[4]))
died = datetime(int(m[5]), int(m[6]), int(m[7]))
assert (name, born, died) == ("Einstein", datetime(1879, 3, 14), datetime(1955, 4, 18))`}
    />

    <p>
        Notice how we had to write the date parsing logic twice, and we actually had to care about how the date parsing
        worked. We couldn’t just treat it as a black box.
    </p>

    <p>
        Being able to encapsulate the parsing logic is immensely useful if you want to extend your parsers in the
        future. We could for example extend our date parser to parse other kinds of formats:
    </p>

    <Highlight
        language={python}
        code={`# Parses e.g. "October" as the number 10
month = (
  string("January").result(1)
  | string("February").result(2)
  | string("March").result(3)
  | string("April").result(4)
  | string("May").result(5)
  | string("June").result(6)
  | string("July").result(7)
  | string("August").result(8)
  | string("September").result(9)
  | string("October").result(10)
  | string("November").result(11)
  | string("December").result(12)
)

# E.g. January 1st 1970
named_date_parser = seq(
  month,
  string(" "),
  number,
  (string("th") | string("nd") | string("st")),
  string(" "),
  number
).combine(lambda month, _1, day, _2, _3, year: datetime(year, month, day))

# Define a date as either a YYYY-MM-DD date, or a date like "January 1st 1970"
date = date | named_date_parser`}
    />

    <p>And now our birthday parser will automagically be able to parse both formats:</p>

    <Highlight
        language={python}
        code={`assert birthday_parser.parse("Einstein lived between 1879-03-14 and 1955-04-18") == ("Einstein", datetime(1879, 3, 14), datetime(1955, 4, 18))
assert birthday_parser.parse("Einstein lived between March 14th 1879 and April 18th 1955") == ("Einstein", datetime(1879, 3, 14), datetime(1955, 4, 18))`}
    />

    <h2>More expressiveness</h2>

    <p>
        If you are anything like me, you have encountered some seemingly simple data that regular expressions just fail
        at. For example, in last year’s <a href="https://adventofcode.com/2022/day/13">Advent of Code</a> you had to parse
        a nested list like:
    </p>

    <Highlight language={python} code={`[[1,2,3],[[1,2],[3,4]]]`} />

    <p>
        Regular expressions cannot parse this. In general, any time you want to count an unbounded number of something
        (in this case the parser needs to count the number of opening and closing brackets), then regular expressions
        will not be enough.
    </p>

    <p>
        Of course, if you are using Python, you can just throw the list into <code>eval</code> and (with complete disregard
        for security) evaluate it as Python code. That works, but if you want to do it properly and avoid surprises when
        people give you nefarious inputs like
    </p>
    <div class="code-block">
        <div class="code-block-controls">
            <a class="play" target="_blank" rel="noreferrer" title="Run" href="https://xkcd.com/353/">▶</a>
        </div>
        <Highlight language={python} code={`[1, 2, 3, __import__("antigravity")]`} />
    </div>
    <p>
        then you may be interested to know that parser combinators <em>can</em> parse this with ease.
    </p>

    <p>
        Formally, regular expressions can parse what are known as <a
            href="https://en.wikipedia.org/wiki/Regular_language"><em>regular languages</em></a
        >, while parser combinators can parse any
        <a href="https://en.wikipedia.org/wiki/Context-free_language"><em>context-free language</em></a>
        <Sidenote text="And more! But usually context-free languages are sufficient." />. All regular languages are
        context-free languages, but not all context-free languages are regular.
    </p>

    <p>
        With a parser combinator, we can parse the above nested-list structure. Though we do need to use some
        forward-declaration trickery to make it work with the parsy library:
    </p>

    <Highlight
        language={python}
        code={`from parsy import forward_declaration, regex, string

number = regex("[0-9]+").map(int)

# Since this is a recursive definition, we need to use forward declarations
array = forward_declaration()
# An array is a comma-separated list of numbers, or nested arrays
array.become(string("[") >> (number | array).sep_by(string(",")) << string("]"))

assert array.parse("[[1,2,3],[[1,2],[3,4]]]") == [[1,2,3],[[1,2],[3,4]]]`}
    />

    <h2>Conclusion</h2>
    <p>
        Parser combinators are a great tool to have in your arsenal. They can help you break down very complex parsing
        tasks into smaller and more manageable chunks. Still, it’s important to use the best tool for the job, and
        regular expressions still have their place for small and simple tasks.
    </p>
    <p>— <span itemprop="author">Aron Granberg</span></p>
    <WorkFooter />
</Blog>

<style>
    .fraction {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        width: max-content;
    }

    .fraction-text {
        text-align: center;
    }

    .fraction-line {
        background-color: black;
        height: 1px;
        width: 100%;
    }
</style>
