<script context="module" lang="ts">
    history.pushState = new Proxy(history.pushState, {
        apply(target, thisArg, argumentsList) {
            Reflect.apply(target, thisArg, argumentsList);
            scrollTo(0, 0);
        },
    });
</script>

<script lang="ts">
    import Fjalltoppen from './components/blogposts/Fjalltoppen.svelte';
    import Hashcode from './components/blogposts/Hashcode.svelte';
    import { Router, Route } from 'svelte-routing';
    import Landing from './components/Landing.svelte';
    import ParserCombinators from './components/blogposts/ParserCombinators.svelte';
    import OptimizationWithTransformers from './components/blogposts/OptimizationWithTransformers.svelte';
    import BlogPostList from './components/BlogPostList.svelte';
    export let url = '';
</script>

<Router {url}>
    <Route path="/fjalltoppen" let:location>
        <Fjalltoppen {location} />
    </Route>
    <Route path="/hashcode" let:location>
        <Hashcode {location} />
    </Route>
    <Route path="/parser_combinators" let:location>
        <ParserCombinators {location} />
    </Route>
    <Route path="/optimization_with_transformers" let:location>
        <OptimizationWithTransformers {location} />
    </Route>
    <Route path="/blog" let:location>
        <BlogPostList {location} />
    </Route>
    <Route path="/" let:location>
        <Landing />
    </Route>
</Router>
