<script lang="ts">
    import { onDestroy, onMount } from 'svelte';

    import { link } from 'svelte-routing';
    import type { RouteLocation } from 'svelte-routing/types/Route';
    import { get } from 'svelte/store';
    import BlogHeader from './BlogHeader.svelte';
    import BottomNavigation from './BottomNavigation.svelte';
    import { onPossibleLayoutChange } from './layout';
    import { popupSidenote, sidenotes, sortedSidenotes, sortSidenotes } from './sidenote_store';
    import { default as codeStyle } from 'svelte-highlight/styles/agate';
    import { fly } from 'svelte/transition';

    let rightColumn: HTMLDivElement;
    let mainColumn: HTMLDivElement;
    export let location: RouteLocation;

    onMount(() => {
        const unsubscribe = onPossibleLayoutChange(mainColumn, refresh);
        return unsubscribe;
    });

    const refresh = () => {
        if (mainColumn !== undefined) sortSidenotes(mainColumn);
    };

    const unsubscribe = sidenotes.subscribe((sidenotes) => {
        refresh();
    });
    onDestroy(unsubscribe);
</script>

<svelte:head>
    {@html codeStyle}
</svelte:head>
<main class="blog theme-yellow">
    <BlogHeader {location} />
    <article class="content-wrapper" itemscope itemtype="http://schema.org/Article">
        <div class="left-column" />
        <div class="content" bind:this={mainColumn}>
            <slot />
        </div>
        <div class="right-column" bind:this={rightColumn}>
            {#each $sortedSidenotes as sidenote, i}
                <aside
                    style={`top: ${sidenote.offset}px`}
                    on:mouseenter={() =>
                        document.getElementById(`sidenote-ref-${sidenote.id}`).classList.add('sidenote-hover')}
                    on:mouseleave={() =>
                        document.getElementById(`sidenote-ref-${sidenote.id}`).classList.remove('sidenote-hover')}
                >
                    <span class="sidenote-number">{i + 1}</span>
                    {sidenote.text}
                </aside>
            {/each}
        </div>
    </article>
    <BottomNavigation {location} className={'bottom-arrow-navigation'} />
</main>
{#if $popupSidenote !== null}
    <div class="popup-sidenote" in:fly={{ y: 200, duration: 400 }} out:fly={{ y: 200, duration: 400 }}>
        <button class="close" on:click={() => popupSidenote.set(null)}><img src="close.svg" alt="Close" /></button>
        {$popupSidenote.text}
    </div>
{/if}

<style>
</style>
