<script lang="ts">
    import { onDestroy } from 'svelte';
    import { popupSidenote, sidenotes, sortedSidenotes } from './sidenote_store';

    export let text: string;
    let element: HTMLElement | null = null;
    let number: number = 0;
    const id = Math.round(Math.random() * 100000) | 0;
    const obj = { id, text, element: null };

    $: {
        sidenotes.update((s) => {
            obj.element = element;
            return s;
        });
    }

    sidenotes.update((sidenotes) => {
        sidenotes.push(obj);
        return sidenotes;
    });

    const unsubscribe = sortedSidenotes.subscribe((sidenotes) => {
        number = sidenotes.findIndex((s) => s.id == id) + 1;
    });

    onDestroy(() => {
        unsubscribe();
        sidenotes.update((sidenotes) => {
            sidenotes.splice(sidenotes.indexOf(obj), 1);
            return sidenotes;
        });
    });

    const toggle = () => {
        popupSidenote.update((s) => (s === null || s.id != id ? obj : null));
    };
</script>

<button
    bind:this={element}
    on:click={toggle}
    class="sidenote"
    id={`sidenote-ref-${id}`}
    data-sidenote={text}
    tabindex="-1">{number}</button
>
