import { get, writable } from 'svelte/store';
export const popupSidenote = writable(null);
export const sidenotes = writable([]);
export const sortedSidenotes = writable([]);
export function sortSidenotes(relativeTo) {
    sortedSidenotes.update((sortedSidenotes) => {
        const m = relativeTo.getBoundingClientRect();
        sortedSidenotes.length = 0;
        for (const sidenote of get(sidenotes)) {
            if (sidenote.element !== null) {
                const r = sidenote.element.getBoundingClientRect();
                const OFFSET = 20;
                sortedSidenotes.push(Object.assign(Object.assign({}, sidenote), { offset: r.top - m.top - OFFSET }));
            }
        }
        sortedSidenotes.sort((a, b) => a.offset - b.offset);
        for (let i = 0; i < sortedSidenotes.length; i++) {
            if (i > 0)
                sortedSidenotes[i].offset = Math.max(sortedSidenotes[i].offset, sortedSidenotes[i - 1].offset + 200);
        }
        return sortedSidenotes;
    });
}
document.addEventListener('keyup', (e) => {
    if (e.key === 'Escape') {
        popupSidenote.set(null);
    }
});
