<script lang="ts">
    import PublishedDate from './PublishedDate.svelte';

    export let date: Date;
    export let name: string;
    export let imageUrl: string;

    const sizeRegex = /(.*)@(\dx)\.(.*)/;
    const sizeMatch = sizeRegex.exec(imageUrl);
    let srcset = [];
    if (sizeMatch != null) {
        if (sizeMatch[2] !== '1x') {
            throw new Error(`Image URL ${imageUrl} does not end with @1x.ext`);
        }
        srcset.push(`${sizeMatch[1]}@1x.${sizeMatch[3]} 1x`);
        srcset.push(`${sizeMatch[1]}@2x.${sizeMatch[3]} 2x`);
    } else {
        srcset.push(`${imageUrl} 1x`);
    }
    const srcsetStr = srcset.join(', ');
</script>

<div class="author-block" itemprop="author" itemtype="http://schema.org/Person">
    <img class="author-portrait" alt={`portrait of ${name}`} itemprop="image" src={imageUrl} srcset={srcsetStr} />
    <div class="author-details">
        <span itemprop="name">{name}</span>
        <PublishedDate {date} />
    </div>
</div>
