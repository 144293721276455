<script lang="ts">
	import { onMount } from "svelte";
	import SimplexNoise from "simplex-noise";
	import colormap from "colormap";
	import * as twgl from "twgl.js";


	class Vector {
		public x: number;
		public y: number;

		constructor(x: number, y: number) {
			this.x = x;
			this.y = y;
		}

		add(v: Vector): Vector {
			this.x += v.x;
			this.y += v.y;
			return this;
		}

		subtract(v: Vector): Vector {
			this.x -= v.x;
			this.y -= v.y;
			return this;
		}

		mul(v: Vector): Vector {
			this.x *= v.x;
			this.y *= v.y;
			return this;
		}

		div(v: Vector): Vector {
			this.x /= v.x;
			this.y /= v.y;
			return this;
		}

		mulS(s: number): Vector {
			this.x *= s;
			this.y *= s;
			return this;
		}

		divS(s: number): Vector {
			this.x /= s;
			this.y /= s;
			return this;
		}

		clone(): Vector {
			return new Vector(this.x, this.y);
		}

		length(): number {
			return Math.sqrt(this.x * this.x + this.y * this.y);
		}

		toArray(): [number, number] {
			return [this.x, this.y];
		}
	}

	export let colorScheme: string;

	let canvasElement: HTMLCanvasElement;
	let enableBlur: boolean = true;
	let enableBloom: boolean = true;

	const hexagon = (radius: number): Vector[] => {
		let points = [];
		for (let i = 0; i < 6; i++) {
			const a = (i / 6) * Math.PI * 2 + Math.PI / 6;
			points.push(new Vector(Math.cos(a) * radius, Math.sin(a) * radius));
		}
		return points;
	};

	class CellularAutomata {
		states: number[][];
		// [s][i] means that if a cell in state s has exactly i neighbours in state 1, then it will stay in that state.
		// Otherwise it will progress to state s+1.
		rules: boolean[][];

		constructor(w: number, h: number, dead_to_alive: number[], alive_to_alive: number[], num_states: number) {
			this.states = [];
			for (let x = 0; x < w; x++) {
				this.states.push([]);
				for (let y = 0; y < h; y++) {
					this.states[x].push(0);
				}
			}

			const dead_to_alive_rule = [];
			for (let i = 0; i < 7; i++) {
				dead_to_alive_rule.push(!dead_to_alive.includes(i));
			}
			const alive_to_alive_rule = [];
			for (let i = 0; i < 7; i++) {
				alive_to_alive_rule.push(alive_to_alive.includes(i));
			}
			const dying_rule = [];
			for (let i = 0; i < 7; i++) {
				dying_rule.push(false);
			}
			this.rules = [dead_to_alive_rule, alive_to_alive_rule];
			for (let i = 0; i < num_states - 2; i++) {
				this.rules.push(dying_rule);
			}
		}

		randomize_states() {
			for (let x = 0; x < this.states.length; x++) {
				for (let y = 0; y < this.states[0].length; y++) {
					this.states[x][y] = Math.random() < 0.1 ? 1 : 0;
				}
			}
		}

		randomize_edges() {
			for (let x = 0; x < this.states.length; x++) {
				for (let y = 0; y < this.states[0].length; y++) {
					if (x < 2 || y < 2 || x >= this.states.length - 2 || y >= this.states[0].length - 2) {
						this.states[x][y] = Math.random() < 0.2 ? 1 : 0;
					}
				}
			}
		}

		get_state(x: number, y: number): number {
			if (x >= 0 && y >= 0 && x < this.states.length && y < this.states[0].length) {
				return this.states[x][y];
			}
			throw `invalid coordinate (${x}, ${y})`;
		}

		try_get_state(x: number, y: number): number | null {
			if (x >= 0 && y >= 0 && x < this.states.length && y < this.states[0].length) {
				return this.states[x][y];
			}
			return null;
		}

		count_hex_neighborhood_alive_states(x: number, y: number): number {
			// For a hex layout which is a regular right/up grid but every other row is offset by half a hex to the right
			if (y % 2 == 0) {
				// Hex has been offset by half a hex to the right
				return (
					(this.try_get_state(x + 1, y + 0) === 1 ? 1 : 0) +
					(this.try_get_state(x + 0, y + 1) === 1 ? 1 : 0) +
					(this.try_get_state(x + 1, y + 1) === 1 ? 1 : 0) +
					(this.try_get_state(x - 1, y - 0) === 1 ? 1 : 0) +
					(this.try_get_state(x - 0, y - 1) === 1 ? 1 : 0) +
					(this.try_get_state(x + 1, y - 1) === 1 ? 1 : 0)
				);
			} else {
				return (
					(this.try_get_state(x + 1, y + 0) === 1 ? 1 : 0) +
					(this.try_get_state(x + 0, y + 1) === 1 ? 1 : 0) +
					(this.try_get_state(x - 1, y + 1) === 1 ? 1 : 0) +
					(this.try_get_state(x - 1, y + 0) === 1 ? 1 : 0) +
					(this.try_get_state(x + 0, y - 1) === 1 ? 1 : 0) +
					(this.try_get_state(x - 1, y - 1) === 1 ? 1 : 0)
				);
			}

			// For a hex layout where right and diagonally up are the main axes of the hex grid
			// return (
			// 	(this.try_get_state(x + 1, y + 0) === 1 ? 1 : 0) +
			// 	(this.try_get_state(x + 0, y + 1) === 1 ? 1 : 0) +
			// 	(this.try_get_state(x - 1, y + 1) === 1 ? 1 : 0) +
			// 	(this.try_get_state(x - 1, y + 0) === 1 ? 1 : 0) +
			// 	(this.try_get_state(x + 0, y - 1) === 1 ? 1 : 0) +
			// 	(this.try_get_state(x + 1, y - 1) === 1 ? 1 : 0)
			// );
		}

		tick() {
			const next_states = [];
			for (let x = 0; x < this.states.length; x++) {
				next_states.push([]);

				for (let y = 0; y < this.states[0].length; y++) {
					let num_alive = this.count_hex_neighborhood_alive_states(x, y);
					const rule = this.rules[this.states[x][y]];
					const new_state = rule[num_alive] ? this.states[x][y] : (this.states[x][y] + 1) % this.rules.length;
					next_states[x].push(new_state);
				}
			}
			this.states = next_states;
		}
	}

	function cosApprox(x: number): number {
		return 1.0 - x*x*0.5;
	}

	const FULL_SCREEN_QUAD_VS = `
#version 300 es
out vec2 v_uv;
const vec2[4] POSITIONS = vec2[](
  vec2(-1.0, -1.0),
  vec2(1.0, -1.0),
  vec2(-1.0, 1.0),
  vec2(1.0, 1.0)
);
const int[6] INDICES = int[](
  0, 1, 2,
  3, 2, 1
);
void main(void) {
  vec2 pos = POSITIONS[INDICES[gl_VertexID]];
  v_uv = pos * 0.5 + 0.5;
  gl_Position = vec4(pos, 0.0, 1.0);
}
`;
	interface Framebuffer {
		framebuffer: WebGLFramebuffer;
		texture: WebGLTexture;
		width: number;
		height: number;
	}

	const createFramebuffer = (gl: WebGLRenderingContext, width: number, height: number): Framebuffer => {
		const framebuffer = gl.createFramebuffer();
		gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
		const texture = gl.createTexture();
		gl.bindTexture(gl.TEXTURE_2D, texture);
		gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
		gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
		gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
		gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.MIRRORED_REPEAT);
		gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.MIRRORED_REPEAT);
		gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);
		gl.bindFramebuffer(gl.FRAMEBUFFER, null);
		gl.bindTexture(gl.TEXTURE_2D, null);
		return {
			framebuffer: framebuffer,
			texture: texture,
			width: width,
			height: height,
		};
	};

	class HexagonBackground {
		hexcoords: Vector[];
		hexScreenCoords: Vector[];
		hexcoordOffset: Vector;
		noise = new SimplexNoise("somerandomseed");
		hex_diameter: number = 20;
		automata: CellularAutomata;
		last_automata_tick: number = 0;
		interpolated_intensities = [];
		colors: [number, number, number][];
		hexagonColors: Float32Array;
		program: twgl.ProgramInfo;
		blurProgram: twgl.ProgramInfo | null;
		copyProgram: twgl.ProgramInfo | null;
		thresholdProgram: twgl.ProgramInfo | null;
		hexagonBuffers: twgl.BufferInfo;
		screen_size: Vector;
		intermediateTex1: Framebuffer | null;
		intermediateTex2: Framebuffer | null;

		constructor(size: Vector, gl: WebGL2RenderingContext | WebGLRenderingContext) {
			this.screen_size = size;

			this.program = twgl.createProgramInfo(gl, [
				`
precision highp float;
attribute vec2 position;
attribute vec4 color;
varying vec4 v_color;
uniform float time;
uniform float hex_diameter;
uniform vec2 resolution;
uniform float scroll_offset;

void main() {
  vec2 pos = (position + vec2(0.0, scroll_offset)) / resolution;
  pos = (pos - 0.5) * 2.0;
  pos.y = -pos.y;
  gl_Position = vec4(pos, 1.0, 1.0);
  v_color = color;
}`,
				`
precision highp float;

uniform vec2 resolution;
uniform float time;
varying vec4 v_color;

void main() {
	gl_FragColor = v_color;
}`,
			]);

			if (gl instanceof WebGL2RenderingContext) {
				this.intermediateTex1 = createFramebuffer(gl, size.x, size.y);
				this.intermediateTex2 = createFramebuffer(gl, size.x, size.y);
				this.copyProgram = twgl.createProgramInfo(gl, [
					FULL_SCREEN_QUAD_VS,
					`
#version 300 es
precision highp float;
in vec2 v_uv;
out vec4 fragColor;
uniform sampler2D u_texture;

void main(void) {
  fragColor = texture(u_texture, v_uv);
}
`,
				]);

				this.thresholdProgram = twgl.createProgramInfo(gl, [
					FULL_SCREEN_QUAD_VS,
					`
#version 300 es
precision highp float;
in vec2 v_uv;
out vec4 fragColor;
uniform sampler2D u_texture;
uniform float thresholdMin;
uniform float thresholdMax;
uniform float multiplier;

void main(void) {
	vec4 col = texture(u_texture, v_uv);
	float grayscale_intensity = dot(col.rgb, vec3(0.299, 0.587, 0.114));
	float blend = smoothstep(thresholdMin, thresholdMax, grayscale_intensity);
	col.rgb *= multiplier;
	fragColor = col * blend; //vec4(col.rgb, col.a * blend);
}
`,
				]);

				this.blurProgram = twgl.createProgramInfo(gl, [
					FULL_SCREEN_QUAD_VS,
					`
#version 300 es
precision highp float;
uniform sampler2D u_texture;
uniform bool u_horizontal;
uniform int u_sampleStep;
out vec4 fragColor;
const float[5] weights = float[](0.2270270, 0.1945945, 0.1216216, 0.0540540, 0.0162162);

ivec2 clampCoord(ivec2 coord, ivec2 size) {
  return max(min(coord, size - 1), 0);
}

void main(void) {
  ivec2 coord = ivec2(gl_FragCoord.xy);
  ivec2 size = textureSize(u_texture, 0);
  vec4 sum = weights[0] * texelFetch(u_texture, coord, 0);
  for (int i = 1; i < 5; i++) {
	ivec2 offset = (u_horizontal ? ivec2(i, 0) : ivec2(0, i)) * u_sampleStep;
	sum += weights[i] * texelFetch(u_texture, clampCoord(coord + offset, size), 0);
	sum += weights[i] * texelFetch(u_texture, clampCoord(coord - offset, size), 0);
  }
  fragColor = sum;
}
`,
				]);
			} else {
				console.log("WebGL2 not supported, skipping some features");
				this.intermediateTex1 = null;
				this.intermediateTex2 = null;
				this.copyProgram = null;
				this.blurProgram = null;
			}

			const scale = 1;

			let hex = hexagon(((0.5 * this.hex_diameter * scale) / Math.cos(Math.PI / 6)) * 1.01);

			// turbidity
			// inferno
			// bathymetry
			// copper
			// magma
			// greens
			this.colors = colormap({
				colormap: colorScheme,
				nshades: 255,
				format: "float",
				alpha: 1,
			});
			for (const col of this.colors) {
				col[0] = 1 - Math.sqrt(1 - col[0]);
				col[1] = 1 - Math.sqrt(1 - col[1]);
				col[2] = 1 - Math.sqrt(1 - col[2]);
			}

			this.hexcoords = [];
			this.hexScreenCoords = [];
			let axis1 = new Vector(1, 0);
			let axis2 = new Vector(0, Math.sin(Math.PI / 3)); // Math.cos(Math.PI / 3), Math.sin(Math.PI / 3));

			let w = size.x;
			let h = size.y;
			// Permit some scrolling
			h *= 4;
			// xmin,ymin,xmax,ymax
			let bbox = [0, 0, w, h];
			let center = new Vector((bbox[0] + bbox[2]) / 2, (bbox[1] + bbox[3]) / 2);

			let hexH = Math.ceil(h / (this.hex_diameter * axis2.y));
			// +1 due to the varying x offset between rows
			let hexW = Math.ceil(w / (this.hex_diameter * axis1.x)) + 1;
			this.automata = new CellularAutomata(hexW, hexH, [2, 3, 4, 5], [1, 3, 4], 5);
			// this.automata = new CellularAutomata(hexW, hexH, [2,3,4,5,6], [2,4,5,6], 4);
			this.automata.randomize_states();
			this.hexcoordOffset = new Vector(hexW / 2, hexH / 2);
			this.interpolated_intensities = [];

			const arrays = {
				position: new Float32Array(hexW * hexH * 6 * 2),
				color: new Float32Array(hexW * hexH * 6 * 4),
				indices: new Uint32Array(hexW * hexH * 4 * 3),
			};
			this.hexagonColors = arrays.color;

			for (let x = 0; x < hexW; x++) {
				this.interpolated_intensities.push([]);
				for (let y = 0; y < hexH; y++) {
					this.interpolated_intensities[x].push(0);
					let hexcoord = new Vector(x, y);
					let centeredHexcoord = new Vector(x, y).subtract(this.hexcoordOffset);
					let p = axis1.clone().mulS(centeredHexcoord.x).add(axis2.clone().mulS(centeredHexcoord.y));
					if (y % 2 == 0) {
						p.add(axis1.clone().mulS(0.5));
					}
					p.mulS(this.hex_diameter);
					p.mulS(scale);
					p.add(center);
					this.hexScreenCoords.push(new Vector(p.x, p.y));
					this.hexcoords.push(hexcoord);

					const hexagonIndex = x * hexH + y;
					const vertexIndex = hexagonIndex * 6;
					const startVertex = vertexIndex;
					for (let i = 0; i < hex.length; i++) {
						arrays.position[(vertexIndex + i) * 2 + 0] = hex[i].x + p.x;
						arrays.position[(vertexIndex + i) * 2 + 1] = hex[i].y + p.y;
						arrays.color[(vertexIndex + i) * 4 + 0] = 1.0;
						arrays.color[(vertexIndex + i) * 4 + 1] = 1.0;
						arrays.color[(vertexIndex + i) * 4 + 2] = 1.0;
						arrays.color[(vertexIndex + i) * 4 + 3] = 1.0;
					}

					// 4 triangles per hexagon
					for (let i = 1; i < hex.length - 1; i++) {
						arrays.indices[(hexagonIndex * 4 + i) * 3 + 0] = startVertex;
						arrays.indices[(hexagonIndex * 4 + i) * 3 + 1] = startVertex + i;
						arrays.indices[(hexagonIndex * 4 + i) * 3 + 2] = startVertex + ((i + 1) % hex.length);
					}
				}
			}

			this.hexagonBuffers = twgl.createBufferInfoFromArrays(gl, {
				position: { numComponents: 2, data: arrays.position, type: Float32Array },
				color: { numComponents: 4, data: arrays.color, type: Float32Array },
				indices: { numComponents: 1, data: arrays.indices, type: Uint32Array },
			});
		}

		destroy() {}

		mapColor(v: number): [number, number, number] {
			const index = Math.max(0, Math.min(this.colors.length - 1, Math.round(v * (this.colors.length - 1))));
			return this.colors[index];
		}

		updateHexagonColors(time: number) {
			let w = this.screen_size.x;
			let h = this.screen_size.y;
			let center = new Vector(w / 2, h / 2);
			let screenRadiusInHexes = center.length() / this.hex_diameter;
			const noiseScale = 0.1;
			const noiseTimeScale = 0.005;

			for (let i = 0; i < this.hexcoords.length; i++) {
				// const hex = this.hexagons[i];
				const hexScreenPos = this.hexScreenCoords[i];
				const hexcoord = this.hexcoords[i];
				const coord = hexScreenPos.clone().subtract(center).divS(this.hex_diameter);
				let centerDist = coord.length() / screenRadiusInHexes;

				let intensity = 0;

				if (false) {
					let noise = this.noise.noise3D(coord.x * noiseScale, coord.y * noiseScale, time * noiseTimeScale);
					noise = noise * 0.5 + 0.5;
					noise = Math.pow(noise, 3);
					intensity = noise;
				} else {
					const state = this.automata.try_get_state(hexcoord.x, hexcoord.y);
					if (state !== null && state >= 1) {
						intensity = 1.0 - (state - 1) / (this.automata.rules.length - 1);
					}
				}

				if (intensity === undefined) {
					console.error("intensity is undefined");
				}

				intensity *= cosApprox(centerDist * 1.4 * 0.5 * Math.PI);
				intensity = Math.max(intensity, 0);
				intensity = Math.min(intensity, 1);

				const interpolation = 0.9;
				this.interpolated_intensities[hexcoord.x][hexcoord.y] =
					this.interpolated_intensities[hexcoord.x][hexcoord.y] * interpolation +
					intensity * (1 - interpolation);

				const col = this.mapColor(this.interpolated_intensities[hexcoord.x][hexcoord.y]);

				for (let j = 0; j < 6; j++) {
					this.hexagonColors[i * 6 * 4 + j * 4 + 0] = col[0];
					this.hexagonColors[i * 6 * 4 + j * 4 + 1] = col[1];
					this.hexagonColors[i * 6 * 4 + j * 4 + 2] = col[2];
					this.hexagonColors[i * 6 * 4 + j * 4 + 3] = 1;
				}
			}
		}

		update(
			gl: WebGLRenderingContext | WebGL2RenderingContext,
			time: number,
			enableBlur: boolean,
			enableBloom: boolean,
			scroll: number,
		) {
			if (Math.round(time / 200) != Math.round(this.last_automata_tick / 200)) {
				this.last_automata_tick = time;
				this.automata.randomize_edges();
				this.automata.tick();
			}

			this.updateHexagonColors(time);

			// Transfer hexagon colors to gpu
			gl.bindBuffer(gl.ARRAY_BUFFER, this.hexagonBuffers.attribs.color.buffer);
			gl.bufferData(gl.ARRAY_BUFFER, this.hexagonColors, gl.DYNAMIC_DRAW);
			gl.bindBuffer(gl.ARRAY_BUFFER, null);

			gl.viewport(0, 0, this.screen_size.x, this.screen_size.y);
			gl.depthFunc(gl.ALWAYS);
			gl.enable(gl.BLEND);
			gl.blendFunc(gl.ONE, gl.ZERO);

			let activeRenderSource = this.intermediateTex2;
			let activeRenderTarget = this.intermediateTex1;
			const flipRenderTargets = () => {
				let x = activeRenderSource;
				activeRenderSource = activeRenderTarget;
				activeRenderTarget = x;
			};

			const renderHexagons = () => {
				gl.useProgram(this.program.program);
				twgl.setBuffersAndAttributes(gl, this.program, this.hexagonBuffers);

				twgl.setUniforms(this.program, {
					time: time,
					hex_diameter: 1.0,
					resolution: this.screen_size.toArray(),
					scroll_offset: scroll,
				});
				twgl.drawBufferInfo(gl, this.hexagonBuffers);
			};

			// Blurs activeRenderSource, the result will still be in activeRenderSource
			const blur = (sampleStep: number) => {
				// Blur horizontally
				gl.bindFramebuffer(gl.FRAMEBUFFER, activeRenderTarget.framebuffer);
				gl.useProgram(this.blurProgram.program);
				twgl.setUniforms(this.blurProgram, {
					u_horizontal: true,
					u_texture: activeRenderSource.texture,
					u_sampleStep: sampleStep,
				});
				gl.drawArrays(gl.TRIANGLES, 0, 6);
				flipRenderTargets();

				// Blur vertically
				gl.bindFramebuffer(gl.FRAMEBUFFER, activeRenderTarget.framebuffer);
				gl.useProgram(this.blurProgram.program);
				twgl.setUniforms(this.blurProgram, {
					u_horizontal: false,
					u_texture: activeRenderSource.texture,
					u_sampleStep: sampleStep,
				});
				gl.drawArrays(gl.TRIANGLES, 0, 6);
				flipRenderTargets();
			};

			const bloom = (target: Framebuffer | null) => {
				gl.bindFramebuffer(gl.FRAMEBUFFER, activeRenderTarget.framebuffer);
				gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
				gl.useProgram(this.thresholdProgram.program);
				twgl.setUniforms(this.thresholdProgram, {
					u_texture: activeRenderSource.texture,
					thresholdMin: 0.3,
					thresholdMax: 0.5,
					multiplier: 1.2,
				});
				gl.drawArrays(gl.TRIANGLES, 0, 6);
				flipRenderTargets();

				blur(4);

				// Add blur to screen
				gl.bindFramebuffer(gl.FRAMEBUFFER, target);
				gl.useProgram(this.copyProgram.program);
				gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
				// gl.blendFunc(gl.ONE, gl.ZERO);
				twgl.setUniforms(this.copyProgram, {
					u_texture: activeRenderSource.texture,
				});
				gl.drawArrays(gl.TRIANGLES, 0, 6);
			};

			if (gl instanceof WebGL2RenderingContext) {
				gl.bindFramebuffer(gl.FRAMEBUFFER, activeRenderTarget.framebuffer);
				gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
				renderHexagons();
				flipRenderTargets();

				if (enableBlur) {
					blur(1);
				}

				// Copy hexagons to screen
				gl.bindFramebuffer(gl.FRAMEBUFFER, null);
				gl.useProgram(this.copyProgram.program);
				twgl.setUniforms(this.copyProgram, {
					u_texture: activeRenderSource.texture,
				});
				gl.drawArrays(gl.TRIANGLES, 0, 6);

				if (enableBloom) {
					bloom(null);
				}
			} else {

				// In case the browser doesn't support WebGL2 (looking at you Safari!)
				// Just render without any fancy post-processing
				renderHexagons();
			}
		}
	}

	onMount(() => {
		let background: HexagonBackground | null = null;

		let callback;
		let t0: number | null = null;

		let prev_size = new Vector(-1, -1);
		let desired_delta_time_ms = 1000 / 20;
		let prevScrollHash = "";
		let prev_t = -10000;
		let animationEventId: number;
		let nextValidAnimationTime = -10000;
		let monotonicT = 0;
		let speed = 0;
		callback = (t: number) => {
			if (t0 === null) {
				t0 = t;
				nextValidAnimationTime = t;
				monotonicT = 0;
			}
			let scrollHash = "" + window.scrollX + window.scrollY;
			const padding = 100;
			const size = new Vector(window.innerWidth, window.innerHeight);
			const deltax = size.x - prev_size.x;
			const deltay = size.y - prev_size.y;
			const need_to_resize = deltax > 0 || deltay > padding || deltax < -padding || deltay < -padding;

			if ((t - prev_t > desired_delta_time_ms || scrollHash != prevScrollHash) || need_to_resize) {
				if (t >= nextValidAnimationTime || need_to_resize) {
					let dt = t - prev_t;
					speed = Math.min(1.0, speed + 5.0 * 0.001 * dt);
					monotonicT += dt * speed*speed;

					let ctx: WebGLRenderingContext | WebGL2RenderingContext = canvasElement.getContext("webgl2");
					if (ctx === null) {
						ctx = canvasElement.getContext("webgl");
					}

					if (need_to_resize) {
						const size_with_padding = new Vector(size.x, size.y + padding);
						canvasElement.width = size_with_padding.x;
						canvasElement.height = size_with_padding.y;

						prev_size = size;
						if (background !== null) {
							background.destroy();
						}
						background = new HexagonBackground(size_with_padding, ctx);
					}
					let scroll = 0; //-window.scrollY * 0.3;
					background.update(ctx, monotonicT, enableBlur, enableBloom, scroll);
				}
				prev_t = t;
			}

			prevScrollHash = scrollHash;
			animationEventId = requestAnimationFrame(callback);
		};
		animationEventId = requestAnimationFrame(callback);

		const onScroll = () => {
			prevScrollHash = "";
			nextValidAnimationTime = prev_t + 50;
			speed = 0;
		};
		document.addEventListener('scroll', onScroll, { passive: true });

		return () => {
			cancelAnimationFrame(animationEventId);
			document.removeEventListener('scroll', onScroll);
			if (background !== null) {
				background.destroy();
			}
		};
	});
</script>

<canvas bind:this={canvasElement} />

<style>
	canvas {
		position: fixed;
		width: 100%;
		margin: 0px;
		z-index: -1;
	}
</style>
