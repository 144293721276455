<script lang="ts">
    import { Link } from 'svelte-routing';
    import type { RouteLocation } from 'svelte-routing/types/Route';
    import { BlogPosts } from './blog_posts';

    export let location: RouteLocation;
    export let className: String = '';

    let visible_posts = BlogPosts.filter((i) => i.published);
    const index = visible_posts.findIndex((i) => i.url == location.pathname);
</script>

{#if index !== -1}
    <nav class="blog-arrow-navigation {className}">
        {#if index - 1 >= 0}
            <Link class="nav-prev" to={visible_posts[index - 1].url}>
                <span class="arrow">←</span>
                {visible_posts[index - 1].title}
            </Link>
        {:else}
            <div class="nav-prev" />
        {/if}
        {#if index + 1 < visible_posts.length}
            <Link class="nav-next" to={visible_posts[index + 1].url}>
                {visible_posts[index + 1].title}
                <span class="arrow">→</span>
            </Link>
        {:else}
            <div class="nav-next" />
        {/if}
    </nav>
{/if}
