<script lang="ts">
    import { Link } from 'svelte-routing';
    import AnimatedBackground from './AnimatedBackground.svelte';
</script>

<main class="landing">
    <AnimatedBackground colorScheme="turbidity" />
    <div class="top-element">
        <div class="banner">
            <h1 class="banner-title">Optimeringsfabriken</h1>
            <h2 class="banner-tagline">Efficient solutions to hard problems</h2>
        </div>
    </div>
    <div class="content-wrapper">
        <div class="content">
            <h2>About us</h2>
            <p>
                We develop software solutions to complex problems using algorithms or machine learning models that are
                tailored to your needs.
            </p>
            <p>
                Our experience with artificial intelligence combined with our expertise in advanced algorithms and
                mathematics enable us to solve a wide range of technically challenging problems. Since efficiency is at
                our core, you can expect a highly performant solution delivered on schedule.
            </p>
            <p>
                Optimeringsfabriken is a Stockholm-based consulting company founded in 2021 by two experienced problem
                solvers with a passion for making things better, one optimization at a time.
            </p>
            <h2>Who are we</h2>
            <div class="profile">
                <img class="profile-photo" src="aron_thumbnail.jpg" alt="Aron Granberg" />
                <div class="profile-text">
                    <h3>Aron Granberg</h3>
                    <p>Aron has a master’s degree in Machine Learning from KTH in Stockholm, Sweden.</p>
                    <p>
                        He is the second place winner in the 2014 <a href="https://progolymp.se"
                            >Swedish Programming Olympiad</a
                        >
                        and a silver medalist in the prestigious
                        <a href="https://en.wikipedia.org/wiki/International_Olympiad_in_Informatics"
                            >International Olympiad in Informatics</a
                        >.
                    </p>
                    <p>
                        Before starting Optimeringsfabriken, Aron worked as a machine learning architect at Embark
                        Studios.
                    </p>
                </div>
            </div>
            <div class="profile">
                <img class="profile-photo" src="marten_thumbnail.jpg" alt="Mårten Wiman" />
                <div class="profile-text">
                    <h3>Mårten Wiman</h3>
                    <p>
                        Mårten has a master’s degree in Computer Science from KTH in Stockholm, and he has previously
                        worked as a software engineer at Google.
                    </p>
                    <p>
                        He has won the Swedish national championships in both <a href="https://progolymp.se"
                            >programming</a
                        >
                        and
                        <a href="https://sv.wikipedia.org/wiki/Skolornas_matematikt%C3%A4vling">mathematics</a>, and
                        received a bronze medal in the
                        <a href="https://en.wikipedia.org/wiki/International_Collegiate_Programming_Contest"
                            >International Collegiate Programming Contest</a
                        >.
                    </p>
                </div>
            </div>
            <h2>News</h2>
            <!-- <div class="blog-post">
                <Link to="/optimization_with_transformers"><h3>Using transformers to optimize code</h3></Link>
                <div class="date">20 Feb 2023</div>
                <p>
                    At Optimeringsfabriken we’re passionate about performance, and we’ve become enamored with the idea
                    of using large language models to automatically generate and validate code optimizations.
                </p>
                <p>
                    Here we demonstrate a simple proof of concept to show how the process could work, and as a bonus we
                    learn when is the best time to go to Mars!
                </p>
                <p>
                    <Link to="/optimization_with_transformers">Read more</Link>
                </p>
            </div> -->
            <div class="blog-post">
                <Link to="/parser_combinators"><h3>Parser combinators are fun</h3></Link>
                <div class="date">06 March 2023</div>
                <p>
                    Do your regexes tend to become unmaintainable messes as soon as you try to apply them to any
                    real-world parsing tasks where the data isn’t already nice and tidy?
                </p>
                <p>
                    Parser combinators are a great (but not very well-known) alternative to regexes that are much more
                    easily maintainable. In this blog post Aron explains what they are and how you can use them.
                </p>
                <p>
                    <Link to="/parser_combinators">Read more</Link>
                </p>
            </div>
            <div class="blog-post">
                <Link to="/hashcode"><h3>Reaching top 10 in Google Hash Code by playing games</h3></Link>
                <div class="date">23 February 2023</div>
                <p>
                    Our team finished 8ᵗʰ in the last ever instance of Hash Code, Google’s programming competition where
                    you have to develop an efficient approximate solutions to an NP-hard problem. Here we describe our
                    strategy, which was a little unusual...
                </p>
                <p>
                    <Link to="/hashcode">Read more</Link>
                </p>
            </div>
            <div class="blog-post">
                <Link to="/fjalltoppen"><h3>Finding the optimal place to go skiing</h3></Link>
                <div class="date">19 November 2021</div>
                <p>
                    A few weeks ago, Aron and I wanted to plan a ski trip together with some of our friends, but we
                    realized that there was no easy way to find which ski resorts matched our expectations.
                </p>
                <p>
                    So we did what anyone would do in our shoes: We used statistical analysis to identify the pros and
                    cons of all Swedish ski resorts!
                </p>
                <p>
                    The end result can be seen at <a href="https://fjalltoppen.se/">fjalltoppen.se</a>.
                </p>
                <p>
                    <Link to="/fjalltoppen">Read more</Link>
                </p>
            </div>
        </div>
        <a class="contact-us" href="mailto:contact@optimeringsfabriken.se"> Contact us </a>
    </div>
</main>

<style>
    :global(body) {
        padding: 0px;
        margin: 0px;
    }
    main {
        margin: 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 20px;

        --yellow: rgba(233, 246, 171, 1);
        --yellow2: rgb(234, 197, 111);
        --yellow3: rgb(211, 174, 89);
        --yellow3-dark: rgb(182, 148, 69);
    }

    .top-element {
        position: relative;
        text-align: center;
        width: 100%;
        height: 800px;
    }

    .banner {
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        padding-top: 20px;
        padding-bottom: 20px;
        backdrop-filter: blur(5px);
    }

    .banner-title {
        color: var(--yellow2);
        font-size: 80px;
        font-weight: 30;
        margin: 0px;
    }

    .banner-tagline {
        color: var(--yellow2);
        font-size: 30px;
        font-weight: 30;
        margin: 0px;
    }

    .content-wrapper {
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        padding-top: 20px;
        padding-bottom: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h2 {
        color: var(--yellow2);
    }

    h3 {
        color: var(--yellow2);
    }

    .content {
        color: white;
        max-width: 800px;
    }

    .profile {
        display: flex;
        align-items: center;
        padding: 16px 0px;
    }

    .profile-photo {
        padding-left: 0px;
        margin-left: 0px;
        flex: 0.5;
        min-width: 0px;
    }

    .profile-text {
        padding: 4px 0px;
        padding-left: 30px;
        flex: 1;
    }

    .contact-us {
        margin-top: 200px;
        margin-bottom: 300px;
        background: var(--yellow3);
        padding: 8px;
        border: none;
        color: white;
        border-radius: 3px;
        padding: 24px 48px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        cursor: pointer;
        transition: 100ms background-color;
        font-size: 16pt;
    }

    .contact-us:hover {
        background-color: var(--yellow3-dark);
    }

    .blog-post {
        background: rgba(0, 0, 0, 0.5);
        border: 4px solid var(--yellow3-dark);
        padding: 16px;
        margin-bottom: 40px;
    }

    .blog-post h3 {
        margin-bottom: 2px;
        margin-top: 2px;
    }

    .date {
        font-size: 12pt;
    }

    @media (max-width: 1000px) {
        .banner-title {
            font-size: 60px;
        }

        .banner-tagline {
            font-size: 20px;
            font-weight: 200;
        }

        .content-wrapper {
            max-width: calc(100% - 60px);
            padding-left: 30px;
            padding-right: 30px;
        }

        .content {
            max-width: 100%;
        }
    }

    @media (max-width: 600px) {
        .banner-title {
            font-size: 40px;
        }

        .banner-tagline {
            font-size: 15px;
            font-weight: 300;
        }

        .content-wrapper {
            max-width: calc(100% - 40px);
            padding-left: 20px;
            padding-right: 20px;
        }

        .profile {
            flex-direction: column;
        }

        .profile-photo {
            max-height: 300px;
        }
    }

    @media (max-height: 600px) {
        .top-element {
            height: 400px;
        }
    }
</style>
