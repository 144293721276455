export const BlogPosts = [
    {
        id: 'fjalltoppen',
        url: '/fjalltoppen',
        title: 'The optimal place to go skiing',
        title_long: 'Finding the optimal place to go skiing',
        date: new Date('2021-11-19'),
        published: true,
    },
    {
        id: 'hashcode',
        url: '/hashcode',
        title: 'Playing games in Google Hash Code',
        title_long: 'Reaching top 10 in Google Hash Code by playing games',
        date: new Date('2023-02-23'),
        published: true,
    },
    {
        id: 'parser_combinators',
        url: '/parser_combinators',
        title: 'Parser Combinators are fun!',
        title_long: 'Having trouble understanding your regexes? Try parser combinators!',
        date: new Date('2023-03-06'),
        published: true,
    },
    {
        id: 'optimization_with_transformers',
        url: '/optimization_with_transformers',
        title: 'Optimizing code with LLMs',
        title_long: 'Using transformers to optimize code',
        date: new Date('2023-02-17'),
        published: false,
    },
];
export function getBlogPostMeta(id) {
    const res = BlogPosts.find((post) => post.id === id);
    if (res === undefined)
        throw new Error(`Blog post with id ${id} not found`);
    return res;
}
