<script lang="ts">
    import { Link } from 'svelte-routing';
    import type { RouteLocation } from 'svelte-routing/types/Route';
    import BottomNavigation from './BottomNavigation.svelte';

    export let location: RouteLocation;
    let open = false;

    const toggle = () => {
        open = !open;
    };
</script>

<nav class="blog-top-navigation">
    <div class="mobile-nav">
        <Link to="/">
            <img src="optimeringsfabriken_icon_bw.svg" alt="icon for Optimeringsfabriken" />
            Optimeringsfabriken
        </Link>
        <button class="hamburger" aria-label="Open menu" on:click={toggle}><span /><span /><span /></button>
    </div>
    <div class="nav-container" class:open>
        <div class="spacer"><div class="divider" /></div>
        <Link class="hide-on-small-nav" to="/">
            <img src="optimeringsfabriken_icon_bw.svg" alt="icon for Optimeringsfabriken" />
            Optimeringsfabriken
        </Link>
        <div class="spacer divider hide-on-small-nav"><div class="divider" /></div>
        <Link to="/">About</Link>
        <div class="spacer divider"><div class="divider" /></div>
        <Link to="/blog">Blog</Link>
        <div class="spacer"><div class="divider" /></div>
    </div>
</nav>
<BottomNavigation {location} className="top-arrow-navigation" />
