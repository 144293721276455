<script lang="ts">
    import type { RouteLocation } from 'svelte-routing/types/Route';
    import Author from '../Author.svelte';
    import Blog from '../Blog.svelte';
    import { getBlogPostMeta } from '../blog_posts';
    import Sidenote from '../Sidenote.svelte';
    export let location: RouteLocation;
</script>

<Blog {location}>
    <img
        class="full-width-image ratio960-640"
        src="fjalltoppen_looking.jpg"
        alt="Snowboarder looking at mountains in the distance"
    />
    <h1 itemprop="name">Finding the optimal place to go skiing</h1>
    <Author name="Mårten Wiman" date={getBlogPostMeta('fjalltoppen').date} imageUrl="/marten_thumbnail_80x80@1x.webp" />
    <p>
        A few weeks ago, Aron and I wanted to plan a ski trip together with some of our friends. Planning vacations
        involving 8 people with different schedules is always a challenge, but we managed to find a few days in February
        that worked for everyone.
    </p>
    <p>The only question was: Where would we go? 🤔</p>
    <!-- <p>
            Since we would only be away for a few days (and didn't want to travel by air out of environmental concern) we didn't want to travel too far from Stockholm.
            We also knew that our group was a fairly diverse one when it came to skiing abilities: Some of us were experienced skiers, while others hadn't stood on a pair of skis in more than 15 years.
        </p> -->
    <!-- <p>
            We thought about the mountains we had visited before but every place we could think of was either too far away or didn't have enough easy and challenging slopes for us.
        </p> -->
    <p>
        Some googling led us to a few articles about the largest ski resorts in Sweden, but we realized that making a
        spreadsheet with the travel times and the pros and cons of each resort would require a lot of manual work.
    </p>
    <p>
        So instead we did what anyone would do in our shoes: We used statistical analysis to identify the pros and cons
        of all Swedish ski resorts, and then we built a website on top! <Sidenote
            text="This is what everyone would do, right?"
        />
        🤔
    </p>
    <img class="half-width-image ratio-square" src="fjalltoppen.webp" alt="Fjälltoppen" style="max-width: 40%;" />
    <h2>Fjälltoppen</h2>
    <p>
        At <a href="https://fjalltoppen.se/">fjalltoppen.se</a>, people looking for the best place to go skiing can fill
        out a few questions about what they’re looking for in a ski resort, and are then shown a list of places ordered
        by how well they match the requirements.
    </p>
    <p>
        When we were done with the website we entered <a
            href="https://fjalltoppen.se/s%C3%B6k?0=0+2+3&5=13&8=4762135&6=18000&4=4">our own preferences</a
        > and the ski resort that came out on top was Romme Alpin, so that’s where we’re going this winter. Data-driven vacation
        planning for the win! ⛷️
    </p>
    <p>— <span itemprop="author">Mårten</span></p>
</Blog>
