<script lang="ts" context="module">
    export type Entry = {
        title: string;
        examples: string;
        description: string;
    };
</script>

<script lang="ts">
    import { Highlight } from 'svelte-highlight';
    import python from 'svelte-highlight/languages/python';

    export let entries: Entry[];
</script>

<div class="details-table">
    <div class="details-row header">
        <div class="details-title">Title</div>
        <div class="details-examples">Examples</div>
        <div class="details-description">Description</div>
    </div>
    {#each entries as entry}
        <div class="details-row">
            <div class="details-title">
                <Highlight language={python} code={entry.title} />
            </div>
            <div class="details-examples">
                <Highlight language={python} code={entry.examples} />
            </div>
            <div class="details-description">{@html entry.description}</div>
        </div>
    {/each}
</div>
