<script lang="ts">
    import python from 'svelte-highlight/languages/python';
    import Highlight from 'svelte-highlight';
    import type { RouteLocation } from 'svelte-routing/types/Route';

    import Blog from '../Blog.svelte';
    import Sidenote from '../Sidenote.svelte';
    import WorkFooter from '../WorkFooter.svelte';
    import Author from '../Author.svelte';
    import { getBlogPostMeta } from '../blog_posts';
    export let location: RouteLocation;
</script>

<Blog {location}>
    <div class="full-width-image mars-background">
        <img src="images/mars/mars.svg" class="ratio-square" alt="Mars" />
    </div>
    <h1>Using transformers to optimize code</h1>
    <Author
        name="Mårten Wiman"
        date={getBlogPostMeta('optimization_with_transformers').date}
        imageUrl="/marten_thumbnail_80x80@1x.webp"
    />
    <p>
        Ever since I first started programming, I’ve always derived satisfaction from making programs run as fast as
        possible. As a kid, I would write chess engines and iteratively fix one suboptimality after another, making the
        program more and more efficient. There were few things I found more gratifying than shaving 10% off some
        bottleneck in my code.
        <!-- As a kid I would write chess engines and there would always be some small thing I could do to make them even faster... -->
    </p>
    <p>
        <!-- So when I started programming for a living, I was in for a disappointment. -->
        So imagine my disappointment when I started programming professionally and discovered that, at most companies, improving
        performance is often not a priority at all. The simple reason is that optimizing code takes a lot of time, and programmers
        are expensive. So, from a business perspective, it often makes more sense to just buy more hardware instead of fixing
        the code.
        <!-- I’ve just had to learn to accept that this is how it is, but it still stings every time I see sluggish
        code running in production. -->
    </p>
    <p>
        However, with the emergence of really good language models like ChatGPT, I’m beginning to hope that we may soon
        have better tools to help us identify and fix performance bottlenecks. I believe these tools will be able to
        help us optimize code while requiring much less human effort than previously.
    </p>
    <p>
        In this blog post, I’m going to explore what the current generation of language models are capable of when it
        comes to generating and validating optimizations. They can already do some pretty impressive things, but as
        we’ll soon see, one needs to be careful because the generated code is not always correct.
    </p>
    <h2>How to reverse a string</h2>
    <p>
        Let’s start with a simple example. Say we have the following Python program for solving the all-important
        problem of reversing a string:
    </p>
    <Highlight
        language={python}
        code={`s = input().strip()
r = ""
for c in s:
    r = c + r
print(r)`}
    />
    <p>
        This is a really bad solution to the problem, because in each iteration of the loop we construct a new string.
        This means that the program’s time complexity is <code>O(n²)</code>, which is way slower than it has any right
        to be. So let’s ask <a href="https://openai.com/blog/openai-codex/">OpenAI Codex</a><Sidenote
            text="The language model that powers GitHub Copilot."
        /> to optimize it for us by giving it the following prompt:
    </p>
    <Highlight
        language={python}
        code={`# Please optimize the following code:
s = input().strip()
r = ""
for c in s:
    r = c + r
print(r)

# Equivalent but faster code:`}
    />
    <p>Codex responds with:</p>
    <Highlight
        language={python}
        code={`s = input().strip()
print(s[::-1])`}
    />
    <p>
        This is a much better solution, because it uses Python’s built-in string slicing to reverse the string in <code
            >O(n)</code
        >, which is the optimal time complexity. I actually think that what the model did here is quite impressive,
        because it did not only have to write a program to reverse a string, but it also had to understand that this was
        what the original program was doing.
    </p>

    <h2>When to go to Mars</h2>
    <p>
        Let’s try solving a slightly more complex problem: Going to Mars. Or, more specifically, deciding when is the
        best time to go to Mars. You see, the distance between Earth and Mars varies periodically, and the distance is
        at its shortest once every 26 months<Sidenote
            text="This is simplified; in reality, the period is not exactly 26 months."
        />. So the question we ask ourselves is whether there will be a Mars launch window in any given year.
    </p>
    <p>
        This is actually a problem that I wrote for a programming competition a few years ago. If you’d like to try
        solving it yourself you can submit your solution over at <a href="https://open.kattis.com/problems/marswindow"
            >kattis.com</a
        >.
    </p>
    <p>Here’s my solution to the problem:</p>
    <Highlight
        language={python}
        code={`def is_launch_window(target_year: int) -> bool:
    # There was a Mars launch window in April 2018.
    current_month = 4
    current_year = 2018

    # Jump forward 26 months at a time until we reach the target year.
    while current_year < target_year:
        current_month += 26
        while current_month > 12:
            current_month -= 12
            current_year += 1

    return current_year == target_year`}
    />
    <p>
        This solution is fast enough if we’re only interested in years in the relatively near future. But what if we
        wanted to know if the year <code>8346268416</code> will have a launch window<Sidenote
            text="Assuming that the period stays exactly 26 months and also that the planets haven’t been engulfed by the Sun."
        />?
    </p>
    <p>
        For the year <code>8346268416</code>, the above program takes 15 minutes to run on my computer, which is frankly
        unacceptable. So let’s see if the language model can help us do something about this.
    </p>
    <p>Using the same prompt format as before, we get the following response from Codex:</p>
    <Highlight
        language={python}
        code={`def is_launch_window(target_year: int) -> bool:
    # There was a Mars launch window in April 2018.
    current_month = 4
    current_year = 2018

    # Jump forward 26 months at a time until we reach the target year.
    while current_year < target_year:
        current_month += 26
        current_year += current_month // 12
        current_month %= 12

    return current_year == target_year`}
    />
    <p>
        The model has gotten rid of the inner while loop, but I was hoping that it would do something about the outer
        while loop as well. Well, it’s usually a good idea to do just one optimization at a time, so let’s not be too
        greedy.
    </p>
    <p>
        If we run the model’s code on the year <code>8346268416</code>, it <em>only</em> takes 8 minutes to get an answer,
        so it’s actually about twice as fast as the original solution, which is definitely something!
    </p>
    <h3>Testing the optimization</h3>
    <p>
        However, it’s not obvious that the optimization is even correct. Luckily, we have a language model at our
        disposal, so we can use it to generate tests to validate the solution’s correctness. Let’s give it the following
        prompt:
    </p>
    <Highlight
        language={python}
        code={`Generate 10 test cases for the following solution:

# Start of solution
def is_launch_window(target_year: int) -> bool:
    # There was a Mars launch window in April 2018.
    current_month = 4
    current_year = 2018
    
    # Jump forward 26 months at a time until we reach the target year.
    while current_year < target_year:
        current_month += 26
        current_year += current_month // 12
        current_month %= 12
    
    return current_year == target_year
# End of solution

Test 1: is_launch_window`}
    />
    <p>The language model then happily generates 10 test cases for us:</p>
    <Highlight
        language={python}
        code={`Test 1: is_launch_window(2010)
Test 2: is_launch_window(2018)
Test 3: is_launch_window(2026)
Test 4: is_launch_window(2029)
Test 5: is_launch_window(2032)
Test 6: is_launch_window(2569)
Test 7: is_launch_window(3020)
Test 8: is_launch_window(4141)
Test 9: is_launch_window(6000)
Test 10: is_launch_window(10000)
`}
    />
    <p>
        That seems like a good mix of different launch windows! Let’s see if the original solution and the optimized
        solution produce the same outputs on the test cases.
    </p>
    <table>
        <tr>
            <th>Input</th>
            <th>Original solution</th>
            <th>Optimized solution</th>
        </tr>
        <tr>
            <td>2010</td>
            <td>no</td>
            <td>no</td>
        </tr>
        <tr>
            <td>2018</td>
            <td>yes</td>
            <td>yes</td>
        </tr>
        <tr>
            <td>2026</td>
            <td>yes</td>
            <td>no <span class="error-dot">✖</span></td>
        </tr>
        <tr>
            <td>2029</td>
            <td>yes</td>
            <td>yes</td>
        </tr>
        <tr>
            <td>2032</td>
            <td>no</td>
            <td>no</td>
        </tr>
        <tr>
            <td>2569</td>
            <td>no</td>
            <td>no</td>
        </tr>
        <tr>
            <td>3120</td>
            <td>no</td>
            <td>no</td>
        </tr>
        <tr>
            <td>4141</td>
            <td>yes</td>
            <td>yes</td>
        </tr>
        <tr>
            <td>6000</td>
            <td>yes</td>
            <td>yes</td>
        </tr>
        <tr>
            <td>10000</td>
            <td>yes</td>
            <td>yes</td>
        </tr>
    </table>
    <p>
        Oh no, the optimized solution is wrong for the year <code>2026</code>! What should we do now? Well, we can
        always turn to the language model again and see if it can help us fix the problem.
    </p>
    <h3>Bug fixing</h3>
    <p>To get the language model to fix the bug in its code, I’m using this prompt:</p>
    <Highlight
        language={python}
        code={`# Here is a slow but correct solution to a problem I'm trying to solve:

# Start of solution
<The original solution>
# End of solution

# Here is a faster but buggy solution to the same problem:

# Start of solution
<The optimized solution>
# End of solution

# Here is an example where the faster solution is wrong.
Input: 2026
Expected output: yes
Actual output: no

# Please rewrite the fast solution and fix the bug.
# The new solution should be equivalent to the slow but correct solution.

# Start of solution`}
    />
    <p>
        To ensure the model doesn’t just copy the code, we’ll use a temperature of <code>1.0</code>
        <Sidenote
            text="With T=0.0, it simply responds with the unmodified solution, without any bug fixes. This makes sense: Greedy decoding is very prone to repetition, and in this case we're actively
        encouraging the model to reuse code from the prompt, so it is no surprise that it copies the code verbatim."
        />. Choosing such a high temperature might cause the model to generate nonsense, but in that case we can just
        decrease the temperature a bit.
    </p>
    <p>Here’s the output we get:</p>
    <Highlight
        language={python}
        code={`def is_launch_window(target_year: int) -> bool:
    # There was a Mars launch window in April 2018.
    current_month = 4
    current_year = 2018

    # Jump forward 26 months at a time until we reach the target year.
    while current_year < target_year:
        current_month += 26
        current_year += current_month // 12
        current_month %= 12

    return (current_year == target_year) or ((current_year == target_year + 1) and (current_month < 4))`}
    />
    <p>
        The complicated condition on the final line looks fishy, and indeed if we run the code on the “test suite” we
        generated earlier, we can see that although it fixes the problem for the year <code>2026</code>, it claims that
        <code>3120</code> will also be launch window year. So we still haven’t fixed the bug.
    </p>
    <p>
        But because we’re now using a positive temperature, we may get a new output if we rerun the model. So let’s try
        again:
    </p>
    <Highlight
        language={python}
        code={`def is_launch_window(target_year: int) -> bool:
    # There was a Mars launch window in April 2018.
    current_month = 4
    current_year = 2018

    # Jump forward 26 months at a time until we reach the target year.
    while current_year < target_year:
        current_month += 26
        current_year += current_month // 12
        current_month %= 12
        if current_month == 0:
            current_month = 12
            current_year -= 1

    return current_year == target_year`}
    />
    <p>This time the generated code actually passes all the tests in our test suite!</p>
    <p>
        And if we take a closer look at what the code is doing, the fix actually makes sense: The buggy solution
        computed the number of passed years as <code>current_month // 12</code>, which meant that December (month 12)
        was counted as part of the next year. This meant that the launch window in December 2026 was counted as part of
        2027, which is why the buggy solution gave the wrong answer for the year 2026.
    </p>
    <p>
        The fixed solution remedies this by subtracting one from the year if the month is December. This is not the most
        elegant solution to the problem, but it does work.
    </p>
    <p>
        It is worth noting that fixed solution is actually a bit slower than the buggy solution, but it is still a good
        30 % faster than the original solution. It is not as fast as I would like it to be, but I’ll save further
        optimizations for a future blog post.
    </p>
    <h3>Conclusion</h3>
    <p>In this post I’ve shown how a language model can be used to:</p>
    <ol>
        <li>Suggest optimizations to existing code.</li>
        <li>Generate a test suite to verify the correctness of the optimizations.</li>
        <li>Debug the optimizations when necessary.</li>
    </ol>
    <p>
        I think the most interesting thing about this is that all of these steps can in principle be carried out
        automatically. A tool that can automatically generate trustworthy optimizations would dramatically reduce the
        cost of improving the performance of existing code. This would ultimately translate to businesses needing to buy
        less hardware, which would be good news for the environment.
    </p>
    <p>
        It would also be good news for me, because I would no longer have to put up with all the slow code that I
        currently see running in production everywhere I look. But perhaps my gratification is secondary.
    </p>
    <p>— <span itemprop="author">Mårten Wiman</span></p>
    <WorkFooter />
</Blog>
