<script lang="ts">
    import { onDestroy, tick } from 'svelte';
    import { fade, fly } from 'svelte/transition';

    let container: HTMLElement;
    let canvas: HTMLCanvasElement;
    let loadingBar: HTMLElement;
    let progressBarFull: HTMLElement;
    let errorMessage: string | null = null;
    let errorType: 'error' | 'warning' = 'error';
    export let buildUrl: string;

    // Shows a temporary message banner/ribbon for a few seconds, or
    // a permanent error message on top of the canvas if type=='error'.
    // If type=='warning', a yellow highlight color is used.
    // Modify or remove this function to customize the visually presented
    // way that non-critical warnings and error messages are presented to the
    // user.
    function unityShowBanner(msg: string, type: 'error' | 'warning') {
        errorMessage = msg;
        errorType = type;

        if (type === 'warning') {
            setTimeout(() => {
                errorMessage = null;
            }, 5000);
        }

        // function updateBannerVisibility() {
        //     warningBanner.style.display = warningBanner.children.length ? 'block' : 'none';
        // }
        // var div = document.createElement('div');
        // div.innerHTML = msg;
        // warningBanner.appendChild(div);
        // if (type == 'error') div.style = 'background: red; padding: 10px;';
        // else {
        //     if (type == 'warning') div.style = 'background: yellow; padding: 10px;';
        //     setTimeout(function () {
        //         warningBanner.removeChild(div);
        //         updateBannerVisibility();
        //     }, 5000);
        // }
        // updateBannerVisibility();
    }

    var loaderUrl = buildUrl + '/builds.loader.js';
    var config = {
        dataUrl: buildUrl + '/builds.data.gz',
        frameworkUrl: buildUrl + '/builds.framework.js.gz',
        codeUrl: buildUrl + '/builds.wasm.gz',
        streamingAssetsUrl: 'StreamingAssets',
        companyName: 'Optimeringsfabriken',
        productName: 'Hashcode2022-final',
        productVersion: '1.0',
        showBanner: unityShowBanner,
    };

    // By default Unity keeps WebGL canvas render target size matched with
    // the DOM size of the canvas element (scaled by window.devicePixelRatio)
    // Set this to false if you want to decouple this synchronization from
    // happening inside the engine, and you would instead like to size up
    // the canvas DOM size and WebGL render target sizes yourself.
    // config.matchWebGLToCanvasSize = false;

    // if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
    //     // Mobile device style: fill the whole browser client area with the game canvas:

    //     var meta = document.createElement('meta');
    //     meta.name = 'viewport';
    //     meta.content =
    //         'width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes';
    //     document.getElementsByTagName('head')[0].appendChild(meta);
    //     container.className = 'unity-mobile';
    //     canvas.className = 'unity-mobile';

    //     // To lower canvas resolution on mobile devices to gain some
    //     // performance, uncomment the following line:
    //     // config.devicePixelRatio = 1;
    // } else {
    //     // Desktop style: Render the game canvas in a window that can be maximized to fullscreen:

    //     canvas.style.width = '960px';
    //     canvas.style.height = '600px';
    // }

    let state: 'placeholder' | 'loading' | 'loaded' = 'placeholder';
    let instance: any | null = null;

    const onload = async () => {
        state = 'loading';
        await tick();
        createUnityInstance(canvas, config, (progress) => {
            progressBarFull.style.width = 100 * progress + '%';
        })
            .then((unityInstance) => {
                instance = unityInstance;
                state = 'loaded';
                // fullscreenButton.onclick = () => {
                //     unityInstance.SetFullscreen(1);
                // };
            })
            .catch((message) => {
                alert(message);
            });
    };
</script>

<svelte:head>
    <script src={loaderUrl}></script>
</svelte:head>

{#if state === 'placeholder'}
    <div id="unity-container" bind:this={container} class="unity-desktop unity-placeholder">
        <button class="unity-placeholder-play-button" on:click={onload}>▶</button>
        <div class="unity-placeholder-incompatible">This game requires a keyboard and a decent sized screen.</div>
    </div>
{:else}
    <div id="unity-container" bind:this={container} class="unity-desktop">
        <canvas
            id="unity-canvas"
            bind:this={canvas}
            width="800"
            height="600"
            tabindex="-1"
            on:outrostart={() => {
                // Unloads the Unity instance.
                // We do this during a transition.
                // Sadly we cannot make svelte wait for the quit to finish
                // because the transitions have a fixed duration.
                // But hopefully 30ms is ok.
                if (instance !== null) instance.Quit();
            }}
            transition:fade={{ duration: 30 }}
        />
        <div
            id="unity-loading-bar"
            class="unity-loading-bar"
            class:loading={state === 'loading'}
            bind:this={loadingBar}
        >
            <div id="unity-logo" />
            <div id="unity-progress-bar-empty">
                <div id="unity-progress-bar-full" bind:this={progressBarFull} />
            </div>
        </div>
        {#if errorMessage !== null}
            <div class={errorType === 'error' ? 'unity-error' : 'unity-warning'}>
                {errorMessage}
            </div>
        {/if}
    </div>
{/if}
