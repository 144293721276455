export function onPossibleLayoutChange(root, callback) {
    let t = 0;
    let timeoutId = null;
    const refreshRegularly = () => {
        callback();
        t++;
        timeoutId = setTimeout(refreshRegularly, Math.min(1000, t * t));
    };
    const trackedImages = [];
    const onImageLoaded = (e) => callback();
    // Loading images typically cause layout changes
    for (const img of root.getElementsByTagName('img')) {
        trackedImages.push(img);
        img.addEventListener('load', onImageLoaded);
    }
    const onVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            clearTimeout(timeoutId);
            refreshRegularly();
        }
        else {
            clearTimeout(timeoutId);
        }
    };
    document.addEventListener('visibilitychange', onVisibilityChange);
    onVisibilityChange();
    return () => {
        clearTimeout(timeoutId);
        document.removeEventListener('visibilitychange', onVisibilityChange);
        for (const img of trackedImages) {
            img.removeEventListener('load', onImageLoaded);
        }
    };
}
