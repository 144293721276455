<script lang="ts">
    import { onDestroy, onMount } from 'svelte';

    import { Link } from 'svelte-routing';
    import type { RouteLocation } from 'svelte-routing/types/Route';
    import BlogHeader from './BlogHeader.svelte';
    import { BlogPosts } from './blog_posts';
    import PublishedDate from './PublishedDate.svelte';

    export let location: RouteLocation;
</script>

<main class="blog theme-yellow">
    <BlogHeader {location} />
    <article class="content-wrapper">
        <div class="content">
            <ol class="post-list">
                {#each [...BlogPosts].reverse() as post}
                    {#if post.published}
                        <li><Link to={post.url}>{post.title_long}</Link><PublishedDate date={post.date} /></li>
                    {/if}
                {/each}
            </ol>
            <p>
                We don't have any fancy RSS or anything like that. If you want to get notified when we publish new
                stuff, send us an email at <a href="mailto:subscribe@optimeringsfabriken.se"
                    >subscribe@optimeringsfabriken.se</a
                >.
            </p>
        </div>
    </article>
</main>
