<script lang="ts">
    let open = false;

    const toggle = () => {
        open = !open;
    };
</script>

<div class="foldout" on:click={toggle} class:open>
    <div class="divider divider-top">
        <!-- svelte-ignore a11y-missing-attribute -->
        <img src="/divider.svg" />
    </div>
    <div class="foldout-preview">
        <slot name="preview" />
    </div>
    <div class="foldout-content">
        <slot name="content" />
    </div>
    <div class="divider divider-bottom">
        <!-- svelte-ignore a11y-missing-attribute -->
        <img src="/divider_expand.svg" />
    </div>
</div>
