<script lang="ts">
    import type { RouteLocation } from 'svelte-routing/types/Route';
    import Author from '../Author.svelte';
    import BlockQuote from '../BlockQuote.svelte';

    import Blog from '../Blog.svelte';
    import Sidenote from '../Sidenote.svelte';
    import UnityPlayer from '../UnityPlayer.svelte';
    import WorkFooter from '../WorkFooter.svelte';
    export let location: RouteLocation;
</script>

<Blog {location}>
    <img
        class="half-width-image ratio-square"
        src="images/hashcode/santa_sled.webp"
        alt="Cute robot santa on his flying sled. Line art."
    />
    <h1>Reaching top 10 in Google Hash Code by playing games</h1>
    <Author name="Aron Granberg" date={new Date('2023-02-23')} imageUrl="/aron_thumbnail_80x80@1x.webp" />
    <p>
        This is the story of how we ended up in 8ᵗʰ place in <a
            href="https://codingcompetitions.withgoogle.com/hashcode/">Google Hash Code</a
        >, and why we spent the last 30 minutes of the competition fervently playing games.
    </p>
    <h2>Hash Code</h2>
    <p>
        You might have heard of Hash Code. It was an annual team programming contest hosted by Google, and it was a
        little different than most other contests. Yesterday Google announced that Hash Code will be discontinued, which
        was an incredibly sad piece of news, because Hash Code was such a special competition.
    </p>
    <p>
        Hash Code eschewed the strict programming competition formula of having a set of algorithmic problems that can
        be solved perfectly, and substituted it with chaos. There was only a single task, which was impossible to solve
        perfectly in any reasonable amount of time. Instead, all teams competed to solve the task as well as possible,
        using whatever tools they thought might be useful.
    </p>
    <p>
        A lot of people clearly thought this was a fun concept, because last year more than 10&nbsp;000 teams
        participated in the qualifications. The top 40 teams qualified for the finals, and our team <Sidenote
            text="Omogen Heap, consisting of Johan Sannemo, Mårten Wiman, Simon Lindholm and Aron Granberg."
        />
        was among the lucky few!
    </p>
    <p>
        To commemorate Hash Code, and get a bit sentimental, I have written up our experience from last year’s finals
        where our team used a weird strategy befitting this weird competition.
    </p>
    <h2>The problem</h2>
    <p>
        The finals featured a very Christmassy problem: Santa had a ton of gifts to deliver, and we had to help him plan
        his route so as to deliver as many gifts as possible. <a
            href="https://codingcompetitions.withgoogle.com/hashcode/round/00000000008cacc6/0000000000aff4a0"
            >The full problem statement</a
        >
        is quite long, but I’ll do my best to summarize it here <Sidenote
            text="I’ve omitted a bunch of details that turn out to not be very relevant. Like carrots. Yes, carrots were a thing."
        />:
    </p>
    <ul>
        <li>Santa has his base at the center of the large grid known as planet Earth.</li>
        <li>Santa has up to 10&nbsp;000 gifts to deliver, each with a given weight and a child it is intended for.</li>
        <li>
            Santa can pick up gifts whenever he is close to his base, and deliver them when he is close enough to a
            child.
        </li>
        <li>
            Santa’s sleigh is pulled by magical reindeer, which can accelerate the sleigh in any of the four cardinal
            directions once per time step.
        </li>
        <li>
            The reindeer, despite being magical, have a limit. Their acceleration decreases as the sleigh gets heavier,
            even going down to zero if the sleigh is too heavy.
        </li>
        <li>
            Santa needs to race against the clock to deliver gifts before Christmas is over (up to 10&nbsp;000 time
            steps).
        </li>
    </ul>
    <p>
        Apparently, Santa’s sleigh obeys a set of physical laws that are almost, but not quite, entirely unlike the
        physical laws of the real world.
    </p>
    <h2>Analysis</h2>
    <img
        class="half-width-image ratio-square"
        src="images/hashcode/santa_investigating.webp"
        alt="Suspicious santa investigating a gift with a magnifying glass. Line art. Close up."
    />
    <p>
        This is quite a tricky problem. Santa has to load gifts, deliver them to some children, then return to get more
        gifts, and continue doing that as efficiently as he can.
    </p>
    <p>
        You might be tempted to draw parallels to the traveling salesman problem, but this problem is a bit different
        since the sleigh has additional state (its weight and velocity), and it is not even trivial to write code to
        just move the sleigh from one point to another given all the crazy acceleration shenanigans going on.<Sidenote
            text="Which didn’t prevent us from trying, but wow that was tricky."
        />
    </p>
    <p>
        But let’s start with the fundamentals. If you have participated in Hash Code before, you might know that it was
        a so-called Open Input competition. This means that all scenarios (6 of them, in this case) on which you will be
        evaluated are available as soon as the competition starts, and during the competition you upload your best
        answer file for each scenario. You don’t necessarily need to use a program to generate the answer files. You
        could read tea leaves to find the answers, if you think that might help. In fact, the easiest scenario is often
        solvable by hand.
    </p>
    <p>
        So how did we approach this problem? Well, the first step when trying to solve any Hash Code problem should in
        my opinion always be to visualize the scenarios to be solved. This is because each scenario typically contains
        data following some specific pattern, which can be exploited to earn more points!
    </p>
    <p>So we started by plotting the coordinates of the children in each scenario:</p>
    <div class="image-grid">
        <img
            class="half-width-image"
            src="images/hashcode/a_an_example.in.txt.webp"
            srcset="images/hashcode/a_an_example.in.txt@1x.webp 1x, images/hashcode/a_an_example.in.txt@2x.webp 2x"
            alt="Visualization for problem A"
        />
        <img
            class="half-width-image"
            src="images/hashcode/b_better_hurry.in.txt.webp"
            srcset="images/hashcode/b_better_hurry.in.txt@1x.webp 1x, images/hashcode/b_better_hurry.in.txt@2x.webp 2x"
            alt="Visualization for problem B"
        />
        <img
            class="half-width-image"
            src="images/hashcode/c_carousel.in.txt.webp"
            srcset="images/hashcode/c_carousel.in.txt@1x.webp 1x, images/hashcode/c_carousel.in.txt@2x.webp 2x"
            alt="Visualization for problem C"
        />
        <img
            class="half-width-image"
            src="images/hashcode/d_decorated_houses.in.txt.webp"
            srcset="images/hashcode/d_decorated_houses.in.txt@1x.webp 1x, images/hashcode/d_decorated_houses.in.txt@2x.webp 2x"
            alt="Visualization for problem D"
        />
        <img
            class="half-width-image"
            src="images/hashcode/e_excellent_weather.in.txt.webp"
            srcset="images/hashcode/e_excellent_weather.in.txt@1x.webp 1x, images/hashcode/e_excellent_weather.in.txt@2x.webp 2x"
            alt="Visualization for problem E"
        />
        <img
            class="half-width-image"
            src="images/hashcode/f_festive_flyover.in.txt.webp"
            srcset="images/hashcode/f_festive_flyover.in.txt@1x.webp 1x, images/hashcode/f_festive_flyover.in.txt@2x.webp 2x"
            alt="Visualization for problem F"
        />
    </div>

    <p>
        As you can see, this was far from just a bunch of random points. All of them had their own distinct patterns,
        and so we started brainstorming:
    </p>
    <ul>
        <li>
            In <code>Better Hurry</code>, Santa could go to one of the corners and deliver all the presents there, and
            then he could go to the opposite corner and pick up presents on the way, without having to stop in the
            middle.<Sidenote
                text="Remember that Santa only has to be close to his base to pick up presents; there is no limit to how fast he may go during the pickup."
            />
        </li>
        <li>
            In <code>Carousel</code>, maybe we could make the sleigh follow the spiral, without ever having to go back
            home to stock up on presents.
        </li>
        <li>
            In <code>Festive Flyover</code> we considered a complicated setup where Santa would fly with an empty sleigh
            in one direction, accelerate to a high velocity in the opposite direction, and pick up a lot of presents on the
            way. Then it wouldn’t matter if the sleigh got too heavy to steer, because Santa could drop off presents until
            he could steer again.
        </li>
    </ul>
    <p>We felt that these were promising ideas, but the idea that we really fell in love with was this one:</p>
    <BlockQuote>
        Instead of trying to implement all these complicated strategies in code, why not make a game out of it?
    </BlockQuote>
    <p>
        This actually made a lot of sense. The competition was only four hours long, and we realized that none of the
        teams would be able to solve the problem even close to optimally within that time frame. But if we could turn
        the problem into a game, we would have at our disposal four neural networks tuned by evolution over millions of
        years and with excellent spatial capabilities. Yes, I’m talking about our brains!
    </p>
    <h2>How to turn the problem into a game</h2>
    <img
        class="half-width-image ratio-square"
        src="images/hashcode/santa_playing_game.webp"
        alt="Santa playing a computer game. Line art."
    />
    <p>
        For the gamification strategy to be feasible, we would have to minimize the number of low-level decisions taken
        by the player. There were many thousands of presents to be delivered, so if the player had to take explicit
        actions to pick up and drop off presents, then playing the game would have taken too much time. Therefore we had
        to make the low-level decisions automatic, leaving only the high-level strategic decisions to the player.
    </p>
    <p>
        So we needed to figure out how to make the game automatically decide which gifts Santa should pick up at his
        base. We would have wanted to just pick up the gifts intended for children that Santa would later fly by, but
        when picking up the gifts we didn’t know where the player would choose to fly, and which gifts we picked up
        would affect where the player could fly because the sleigh’s acceleration depended on its weight. It looked like
        we had a catch-22 on our hands.
    </p>
    <p>
        Except, we could actually solve this using a clever bit of time travel. You see, it didn’t actually matter which
        specific gifts Santa picked up; it only mattered how much they weighed in total. So we could just make Santa
        pick up an unspecified bundle of gifts which was just light enough that the reindeer could still accelerate the
        sleigh. Then, when we came into range of a child, we would retroactively change the past to say that the child’s
        gift <em>totally</em> was among those he picked up last time he visited his base.
    </p>
    <p>
        Using that idea, the game would be able to automatically pick up and deliver gifts, and all the player had to do
        would be to steer the sleigh. Perfect!
    </p>
    <h2>The result</h2>
    <p>
        I implemented the game in Unity in the quickest way possible, for the most part using debug drawing utilities
        from a <a href="https://arongranberg.com/aline/">Unity package</a> I built a couple of years ago.
    </p>
    <p>
        Once the game was ready, we immediately saw that we were onto something. My initial stumbling attempts at <code
            >Excellent Weather</code
        > easily got us the best submitted score for that scenario at that time in the competition. As a bonus, we got a
        much better feel for the problem than we could have ever gotten otherwise (after all, reading tables of numbers only
        gets you so far).
    </p>
    <p>
        Sadly, the game only proved appropriate for 3 of the scenarios: <code>Carousel</code>,
        <code>Excellent Weather</code>
        and <code>Festive Flyover</code>. The other ones had too weird accelerations, or required too much precision,
        which made them very hard to play. But the competition was nearing its end, so we sat down, flexed our fingers,
        and spent the remaining 30 minutes frantically playing the game over and over, trying to beat our own high
        scores.
    </p>
    <p>
        In the end, even though we only submitted solutions to 3 out of 6 scenarios, we still got enough points for an
        8ᵗʰ place. This was our best result ever in Hash Code, and all thanks to the weirdest strategy we’ve ever used
        in a programming competition!
    </p>
    <p>You can play the game too! Maybe you can even beat our high scores!</p>
    <UnityPlayer buildUrl="/hashcode_game/Build" />
    <p>— <span itemprop="author">Aron Granberg</span></p>
    <WorkFooter />
</Blog>
